import Calculator from "./components/Calculator/Calculator";


function App() {
  return (
   <>
   <Calculator/>
  </>
  );
}

export default App;
